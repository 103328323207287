<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <Footer></Footer>
    <div>
      <div class="back-icon">
        <img src="@/assets/previous.svg" width="30" @click="$router.back()" />
      </div>
      <h1 class="header-text">
        <div class="topic" style="padding-top: 0px !important">
          {{ $t("subasset.asset_order") }}
        </div>
      </h1>
      <div class="card-form-login">
        <div class="parent-asset">
          <div class="d-flex justify-content-between">
            <p class="text-gray">{{ $t("subasset.parent_asset") }}</p>
            <button class="add-button" @click="addParent" v-if="!parentAsset">
              + {{ $t("subasset.add_parent_asset") }}
            </button>
          </div>
          <AssetCard
            v-if="parentAsset"
            :asset="parentAsset"
            @delete-link="removeParentAsset"
          ></AssetCard>
        </div>
        <div class="child-asset">
          <div class="d-flex justify-content-between">
            <p class="text-gray">
              {{ $t("subasset.child_asset") }} {{ subAsset.length }}
              {{ $t("subasset.item") }}
            </p>
            <button class="add-button" @click="addChild">
              + {{ $t("subasset.add_child_asset") }}
            </button>
          </div>
          <AssetCard
            v-for="asset in subAssetList"
            :asset="asset"
            :key="asset.id_asset"
            @delete-link="removeChildAsset"
          ></AssetCard>
        </div>
        <p
          class="text-center"
          v-if="!showMore && subAsset.length > 6"
          @click="showMore = true"
        >
          <u style="color: #015FC3; cursor: pointer; "
            >แสดงสินทรัพย์ย่อยทั้งหมด</u
          >
        </p>
      </div>
    </div>
    <div class="line-break"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import AssetCard from "@/components/CustomComponent/SubAsset/AssetCard.vue";

import Loading from "vue-loading-overlay";

import axios from "axios";
import baseUrl from "@/util/backend";
import { authHeader } from "@/store/actions";
import { mapGetters } from "vuex";
export default {
  components: { Loading, Footer, AssetCard },
  data() {
    return {
      isLoading: false,
      id_company: null,
      parentAsset: null,
      subAsset: [],
      showMore: false,
    };
  },
  computed: {
    ...mapGetters({
      getDetailAsset: "getDetailAsset",
    }),
    subAssetList() {
      if (this.showMore) {
        return this.subAsset;
      } else {
        if (this.subAsset.length > 6) {
          const slicedArray = this.subAsset.slice(0, 6);
          return slicedArray;
        }
        return this.subAsset;
      }
    },
  },
  methods: {
    addParent() {
      this.$router.push({
        name: "SelectAsset",
        params: {
          assetId: this.$route.params.assetId,
        },
        query: {
          page: "mainasset",
        },
      });
    },
    addChild() {
      this.$router.push({
        name: "SelectAsset",
        params: {
          assetId: this.$route.params.assetId,
        },
        query: {
          page: "subasset",
        },
      });
    },
    async loadData() {
      this.isLoading = true;
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("getDetailAssets", this.$route.params.assetId);
      this.parentAsset = this.getDetailAsset.asset.parent;
      this.subAsset = this.getDetailAsset.asset.sub_assets;
      this.isLoading = false;
    },
    async removeParentAsset() {
      this.isLoading = true;
      try {
        await axios.delete(`${baseUrl()}asset/delete/subAsset`, {
          ...authHeader(),
          data: {
            id_company: this.id_company,
            id_asset_list: [this.$route.params.assetId],
          },
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
      this.loadData();
    },
    async removeChildAsset(assetId) {
      this.isLoading = true;
      try {
        await axios.delete(`${baseUrl()}asset/delete/subAsset`, {
          ...authHeader(),
          data: {
            id_company: this.id_company,
            id_asset_list: [assetId],
          },
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  padding-top: 92px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
  @media only screen and (max-width: 412px) {
    padding: 10px;
    cursor: pointer;
  }
}

.card-form-login {
  text-align: left;
  margin-top: 30px;
  margin-left: 5%;
  margin-right: 5%;
  @media only screen and (min-width: 1025px) {
    // margin-top: 60px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.text-gray {
  color: #7c7c7c;
}

.add-button {
  border: none;
  background: none;
  font-size: 18px;
  color: #007afe;
}
</style>
